<template>
  <b-tabs
    v-model="activeTab"
    class="app-navbar"
  >
    <b-tab-item
      v-for="(tab, tIndex) in navigationTabs"
      :key="tIndex"
      :value="tab.routeName"
      :label="tab.label"
      :disabled="tab.isDisabled"
    />
  </b-tabs>
</template>

<script>
import * as ROUTES from '@/router/routeConstants';

export default {
    data() {
        return {
            navigationTabs: [
                {
                    label: 'Modules',
                    routeName: ROUTES.APPLICATION_ROUTE_NAME,
                    isDisabled: false
                },
                {
                    label: 'Database',
                    routeName: ROUTES.DATABASE_ROUTE_NAME,
                    isDisabled: false
                },
                {
                    label: 'Templates',
                    routeName: ROUTES.APPLICATION_TEMPLATES_ROUTE_NAME,
                    isDisabled: false
                },
                {
                    label: 'Environment variables',
                    routeName: ROUTES.APPLICATION_ENVIRONMENT_VARIABLES_ROUTE_NAME,
                    isDisabled: false
                },
                {
                    label: 'Settings',
                    routeName: ROUTES.APPLICATION_SETTINGS_ROUTE_NAME,
                    isDisabled: false
                }
            ],
            activeTab: this.$router?.history?.current?.name
        };
    },
    watch: {
        activeTab: function() {
            this.$router.push({ name: this.activeTab });
        }
    }
};
</script>

<style lang="scss">
@import '~@/style/variables.scss';

.app-navbar {
    .tabs {
      li {
        a {
          padding: 0.5em 3em;
          font-size: 0.875rem;
          &:hover {
            border-bottom-width: 2px;
          }
        }
        &.is-active {
          a {
            border-bottom-width: 2px;
          }
        }
      }
    }
}
</style>