// route names
export const DASHBOARD_ROUTE_NAME = 'Dashboard';
export const APPLICATION_ROUTE_NAME = 'Application';
export const DATABASE_ROUTE_NAME = 'Database';
export const DATABASE_TABLES_ROUTE_NAME = 'Database Tables';
export const DATABASE_TABLE_DATA_ROUTE_NAME = 'Database Table Data';
export const DATABASE_TABLE_STRUCTURE_ROUTE_NAME = 'Database Table Structure';
export const APPLICATION_TEMPLATES_ROUTE_NAME = 'Templates';
export const APPLICATION_SINGLE_TEMPLATE_ROUTE_NAME = 'Single Template';
export const APPLICATION_SETTINGS_ROUTE_NAME = 'Application Settings';
export const APPLICATION_ENVIRONMENT_VARIABLES_ROUTE_NAME = 'Application Environment Variables';
export const TEST_CENTER_ROUTE_NAME = 'Test Center';
export const MODULE_EDITOR_ROUTE_NAME = 'Module Editor';
export const USERS_ROUTE_NAME = 'Users';
export const AUDIT_LOG_LIST_ROUTE_NAME = 'AuditLogList';
export const AUDIT_LOG_SINGLE_ROUTE_NAME = 'AuditLogSingle';
export const PREVIEW_ROUTE_NAME = 'Preview';
export const VERSION_ROUTE_NAME = 'Version';
export const LOGIN_ROUTE_NAME = 'Login';
export const FORGOT_PASSWORD_ROUTE_NAME = 'ForgottenPassword';
export const RESET_PASSWORD_ROUTE_NAME = 'ResetPassword';
export const LOGIN_CONFIRM_ROUTE_NAME = 'TwoFactorCode';
export const MONITORING_ROUTE_NAME = 'Monitoring';
export const ANALYTICS_ROUTE_NAME = 'Analytics';
export const WORKFLOW_EDITOR_ROUTE_NAME = 'Workflow Editor';
export const LOCALIZATION_ROUTE_NAME = 'Localization';
export const AI_COPILOT_ROUTE_NAME = 'AI Copilot';
